<template>
  <div>
    <TemplateBuilder></TemplateBuilder>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import TemplateBuilder from "@/view/pages/templates/components/TemplateBuilder";
import { SHOW_TEMPLATE } from "@/core/services/store/template.module";

export default {
  name: "TemplateEdit",
  components: {
    TemplateBuilder
  },
  data() {
    return {};
  },
  mounted() {
    const template_uuid = this.$route.params.template_uuid;

    this.$store.dispatch(SHOW_TEMPLATE, template_uuid);

    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Template Edit " + template_uuid }
    ]);

    this.$nextTick(() => {
      const hljs = this.$el.querySelectorAll(".hljs");
      hljs.forEach(hl => {
        hl.classList.remove("hljs");
        hl.classList.add(`language-${hl.classList[1]}`);
      });
    });
  },

  methods: {},
  computed: {
    ...mapGetters(["currentTemplate"])
  }
};
</script>
